import { gql } from 'graphql-tag';

export default gql`
    fragment SummaryFragment on Index {
        id
        title
        path
        publishedAt
        summary
        contentType
        subtype
    }
`;
